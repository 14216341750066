import moment from 'moment'

const calcuTime = (previewStatus, endTime, begin, finish) => {
  // console.log(previewStatus, endTime, begin, finish)
  if (previewStatus !== 1) {
    // if (this.skuStockList.length > 0) {
    //   console.log(this.skuStockList.length > 0)
    //   this.skuStockListindexid = this.skuStockList[0].id
    //   this.skuStockListskuCode = this.skuStockList[0].skuCode
    // }

    // for (const i in this.skuStockList) {
    //   this.skuStockList[i].spData = eval(
    //   `(${this.skuStockList[i].spData})`
    //   )
    // }
    // console.log('eval', this.skuStockList)
  } else {
  /** ******************预售************************/
    // 结束时间
    const over = moment(endTime).format('YYYY-MM-DD')
    // // 在当前日期往后延...天
    var beginDate = moment(`${over}`)
      .subtract(begin, 'days')
      .format('YYYY-MM-DD')

    // console.log(beginDate)

    var finishDate = moment(`${over}`)
      .subtract(finish, 'days')
      .format('YYYY-MM-DD')

    // console.log(finishDate)
    // 截至时间
    // const endDate = moment(endTime).format('YYYY-MM-DD')
    // console.log(endDate)
    return { beginDate, finishDate, endTime }

    // console.log(this.skuStockList)
    // skuStockListarray.forEach((item) => {
    //   if (
    //     (moment(JSON.parse(item.spData)[0].value).isBetween(
    //       now,
    //       endDate
    //     ) &&
    //     JSON.parse(item.spData)[0].value >= beginDate) ||
    //   moment(JSON.parse(item.spData)[0].value).isAfter(beginDate)
    //   ) {
    //     console.log('push')
    //     // date: "2021-07-30", content: null, cid: null
    //     this.selecteds.push({
    //       date: JSON.parse(item.spData)[0].value,
    //       name: JSON.parse(item.spData)[1].value,
    //       content: '有货',
    //       nums: this.shopmessages.minBuy,
    //       stock: item.stock,
    //       skuCode: item.skuCode,
    //       price: item.price,
    //       skuid: item.id,
    //       sjc: new Date(JSON.parse(item.spData)[0].value).getTime()
    //     })
    //   }
    // })
    // console.log('selecteds', this.selecteds)
  }
}

export default calcuTime
