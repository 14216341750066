<template>
  <div id="page">
    <div class="nav">
      <ul>
        <li v-for="(item,index) in headernav" :key="index"
        @click="this.$router.push(item.path)">
          {{item.text}}
        </li>
      </ul>
      <div id="search">
        <div class="logosearch">
          <div id="logo">
            <img @click="this.$router.push('/')" src="../../assets/logo2.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="header">
        <div class="left_img">
          <img :src="thisimgurl" alt="" class="detailimg">
          <div class="carousel_div">
            <button @click="carDivBtnNextLast('last')"
            class="car_left_button is_carbutton"><el-icon><arrow-left /></el-icon></button>
              <ul class="car_img_ul">
                <li class="car_img_li"
                v-for="(i,index) in this.product.albumPics" :key="index">
                  <span class="li_span_img"
                  :class="isactCarimg==index?'isact_carimg':''"
                  @click="isact(index,'isactCarimg')"><img :src="i" alt=""></span>
                </li>
              </ul>
            <button @click="carDivBtnNextLast"
            class="car_right_button is_carbutton"><el-icon><arrow-right /></el-icon></button>
          </div>
        </div>
        <div class="center_car">
          <div class="center_title">
            <span class="yushouCont">转售</span>
            <h4 class="titlefont">{{product.name}}</h4>
          </div>
          <el-form ref="datalist" :model="datalist" label-width="500px">
            <el-form-item label="价 格:" label-width="100px">
              <span class="saleclass">{{product.currencySymbol+pmsResaleProduct.resalePrice+'/'+product.unit}}</span>
            </el-form-item>
            <div class="itemdiv">
              <span class="itemdiv_span">
                买家优先支付
                <strong>{{product.prePayRate+'%'}}</strong>
                订金</span>
            </div>
            <el-form-item label="转售库存:" label-width="100px">
              <span class="stock_num">{{thisStock.stock + product.unit}}</span>
              <div class="dinghuo"><i class="">最小订货量:</i>{{product.minBuy + product.unit}}</div>
            </el-form-item>
            <el-form-item label="转售到期时间:" label-width="100px">
              <span>{{pmsResaleProduct.endTime}}</span>
              <div class="dinghuo"><i class="">最小增货量:</i>{{product.minIncr + product.unit}}</div>
            </el-form-item>
            <div class="yuti">
              <span class="tian">提前预提天数
                <i>
                  {{thisBeginDay}}
                  -{{thisEndDay}}天</i>
              </span>
              <span class="zhuanshouspan">{{product.isResale==1?'允许转售':'不允许转售'}}
                <el-icon><warning-filled /></el-icon>
              </span>
              <span class="spanzs" v-if="product.isResale==1">可在预提前进行转售</span>
            </div>
            <el-form-item label="规 格:" label-width="100px">
              <div class="guige">
                <el-tooltip placement="top" effect="light"
                  v-for="(i,index) in skuStockList" :key="index"
                  :class="isactive==index?'isactive':''">
                  <template #content>
                    <div style="display:flex;align-items: center;">
                      <img :src="i.pic" style="width:80px;height:80px;">
                      <div>
                        <p style="margin-left:10px;color:#999999;line-height: 30px;">
                          名称:
                          <span style="color:#333333;">
                            {{i.spData[1].value?i.spData[1].value:i.spData[0].value}}</span>
                        </p>
                        <p style="margin-left:10px;color:#999999;line-height: 20px;">
                          库存：<span style="color:#333333;">
                            {{pmsResaleProduct.stock+product.unit}}</span>
                        </p>
                        <p style="margin-left:10px;color:#999999;line-height: 30px;">
                          价格：<span style="color:#333333;">
                            {{product.currencySymbol+pmsResaleProduct.resalePrice+'/'+product.unit}}</span>
                        </p>
                        <p style="margin-left:10px;color:#999999;line-height: 20px;">
                          提货日期：<span style="color:#333333;">
                            {{i.spData[0].value}}</span>
                        </p>
                      </div>
                    </div>
                  </template>
                  <span class="btn" @click="isact(index, 'isactive', i)"
                  v-show="index<=3?true:showallgg"
                  >
                    {{i.spData[1].value?i.spData[1].value:i.spData[0].value}}
                  </span>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item class="tihuo_time" label="提货日期:" label-width="100px">
              <div class="block">
                <span>{{validPeriodOffer}}</span>
              </div>
            </el-form-item>
            <el-form-item label="数量:" label-width="100px">
              <el-input-number
                v-model="thisBuyNum"
                :step="product.minIncr"
                size="mini"
                :min="product.minBuy>thisStock.stock ? thisStock.stock : product.minBuy"
                :max="thisStock.stock"
                @change="changeNum(thisBuyNum)"/>
            </el-form-item>
          </el-form>
          <span class="btnspan">
            <div class="btn_div">
              <button class="gobutton gm"
                  @click="toBuyPage">
                  购买
              </button>
              <button class="gobutton gz"
                  @click="likeVarietiy">
                  关注
              </button>
            </div>
          </span>
        </div>
        <div class="right_test">
          <h3 class="righttitle">预估价格</h3>
          <el-form class="right_form"
          label-suffix=":"
          ref="datalist" label-width="259px">
            <el-form-item label="选择规格" label-width="80px">
              <!-- <el-select
                size="mini"
                v-model="thisRightTest.guigevalue"
                placeholder="请选择规格">
                  <el-option
                    v-for="(i,k) in skuStockList" :key="k"
                    :label="i.spData[1].value"
                    :value="i.price">
                  </el-option>
                </el-select> -->
                <span v-for="(i,k) in skuStockList" :key="k">
                  {{i.spData[1].value}}
                </span>
            </el-form-item>
            <el-form-item label="购买重量" label-width="80px">
              <el-input-number
                v-model="thisRightTest.weightvalue"
                :step="product.minIncr"
                size="small"
                :min="product.minBuy>thisStock.stock ? thisStock.stock : product.minBuy"
                :max="thisStock.stock"/>
            </el-form-item>
            <el-form-item label="预提方式" label-width="80px">
              <el-select
                size="mini"
                v-model="thisRightTest.yutivalue"
                placeholder="选择预提方式">
                  <el-option
                    v-for="(i,k) in pmsProductPreTakeWayList" :key="k"
                    :label="i.spec+product.currencySymbol+i.price"
                    :value="i.price">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="付款比例" label-width="80px">
              <span>{{thisRightTest.fukuanvalue+'%'}}</span>
            </el-form-item>
          </el-form>
          <div class="jisuan">
            <p>预提费：<span>{{product.currencySymbol}}
              {{Number(thisRightTest.yutivalue*thisRightTest.weightvalue).toFixed(2)}}</span></p>
            <p>预付款：<span>{{product.currencySymbol}}
              {{Number((thisRightTest.guigevalue * thisRightTest.weightvalue / 100) * this.thisRightTest.fukuanvalue).toFixed(2)}}
              </span></p>
            <p>尾款：<span>{{product.currencySymbol}}
            {{daikuan.toFixed(2)}}
            </span></p>
          </div>
          <div class="heji">
            预计总货款：<span>{{product.currencySymbol}}
              {{Number(allPrice).toFixed(1)}}</span>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="supplier_message">
          <h3>供应商档案</h3>
          <p class="supname">{{companyInfo.companyName}}</p>
          <!-- <span class="supgood">好评率：{{(99.5561).toFixed(2)}}%</span> -->
          <div class="supxiangxi">
            <div class="suplogo">
              <img :src="companyInfo.logo" alt="">
            </div>
            <div class="xxdiv">
              <el-icon><location /></el-icon>
              <span>{{companyInfo.address}}</span></div>
            <div class="xxdiv">
              <el-icon><phone-filled /></el-icon>
              {{companyInfo.phoneNumber}}</div>
            <div class="xxdiv">
              <el-icon><avatar /></el-icon>
              <el-popconfirm
                confirm-button-text="是的"
                cancel-button-text="再考虑一下"
                icon="InfoFilled"
                icon-color="#626AEF"
                :title="product.focusOnMerchant==1?'你要取关该供应商吗?':'你要关注该供应商吗?'"
                @confirm="confirmEvent"
                @cancel="cancelEvent"
              >
                <template #reference>
                  <el-button size="mini">{{product.focusOnMerchant==1?'已关注':'关注'}}</el-button>
                </template>
              </el-popconfirm>
            </div>
            <div class="xxdiv">
              <el-icon><ChatDotRound /></el-icon>
              <button>联系</button>
            </div>
          </div>
        </div>

        <div class="supplier_commodity">
          <h2>咖啡详情</h2>
          <div class="coffee_mes">
            <ul>
              <li class="coffee_li">
                <h4>庄 园：</h4>
                <span class="li_mmsg">{{product.manorName}}</span>
              </li>
              <li class="coffee_li">
                <h4>产 地：</h4>
                <span class="li_mmsg">{{product.originPlace}}</span>
              </li>
              <li class="coffee_li">
                <h4>年 份：</h4>
                <span class="li_mmsg">{{product.productTime}}</span>
              </li>
              <li class="coffee_li">
                <h4>海 拔：</h4>
                <span class="li_mmsg">{{product.altitude + '米'}}</span>
              </li>
              <li class="coffee_li">
                <h4>采摘方式：</h4>
                <span class="li_mmsg">{{product.pickWay}}</span>
              </li>
              <li class="coffee_li">
                <h4>报价方式：</h4>
                <span class="li_mmsg">{{product.offerForm}}</span>
              </li>
              <li class="coffee_li coli1">
                <h4>加工方式：</h4>
                <span class="li_mmsg">{{product.processWay}}</span>
              </li>
              <li class="coffee_li coli1">
                <h4>咖啡风味：</h4>
                <span class="li_mmsg">{{product.flavorDescription}}</span>
              </li>
            </ul>
          </div>
          <div class="commodity_message">
            <el-breadcrumb separator="|">
              <el-breadcrumb-item
              v-for="i in commMess" :key="i">
                <div class="com_body">
                  <span>{{i.cont}}</span>
                  <p>{{i.title}}</p>
                </div>
              </el-breadcrumb-item>
              <!-- <el-breadcrumb-item>promotion management</el-breadcrumb-item> -->
            </el-breadcrumb>
          </div>
          <h2>商品详情</h2>
          <div class="commodity_content">
            <p class="cont_message">
              {{product.detailDesc}}
            </p>
            <div class="cont_img" v-html="product.detailHtml">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="divfooter">
        <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from '../index/Footer.vue'
import { getStore, setStore } from '../../tools/storage.js'

import { toRaw } from 'vue'
import { reactive, ref } from 'vue-demi'
import http from '../../api/http.js'
// import { useRoute } from 'vue-router'
import calcuTime from '../../tools/calculateTime.js'
import { ElMessage } from 'element-plus'
// import moment from 'moment'

// 关注商品接口
const followTheProduct = (data) => {
  return http.post('/mall-portal/mall/pmsfollowtheproduct/followTheProduct', data)
}
// 关注店铺接口
const followTheStore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/followTheStore', data)
}
// 取消关注店铺
const unpmsfollowthestore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/deleteByMerchantId', data)
}
const headernav = [
  { text: '首页', path: '/' },
  { text: '登录', path: '/loginandregister' },
  { text: '移动端', path: '' }
]
export default {
  name: 'VarietiyDetails',
  data () {
    return {
      endTime: '',
      previewStatus: 1,
      isactive: ref(0),
      isactCarimg: ref(0),
      thisimgurl: '', // 选择的图片
      thisStock: 0, // 选择的规格（库存）
      thisBeginDay: 0, // 预提提前开始几天
      thisEndDay: 0, // 预提提前开始几天
      thisBuyNum: 0, // 购买数量

      atime: '',
      thisRightTest: reactive({
        guigevalue: '',
        weightvalue: '',
        yutivalue: '',
        fukuanvalue: ''
      }), // 右侧预估价格
      guigeoption: [{
        label: '1',
        value: '1'
      }],
      // daikuan: 0,
      yunfei: 0,
      allPrice: ref(0), // 预估合计
      commMess: [
        { title: '品牌', cont: '' },
        { title: '上架时间', cont: '' },
        { title: '保存期限', cont: '12个月' },
        { title: '发货地', cont: '' },
        { title: '交货地点', cont: '' }
      ], // 详情头部信息
      datalist: reactive({}),
      guigevalue: '',
      showallgg: ref(false),
      timeTags: reactive([]),
      showallsp: ref(false),
      validPeriodOffer: '', // 转售到期时间
      // comList: reactive({})
      brand: '',
      companyInfo: '',
      couponList: [],
      pmsResaleProduct: [],
      pmsProductPreTakeWayList: [],
      pmsSiteEntityHashMap: [],
      product: '',
      productAttributeList: [],
      productAttributeValueList: [],
      productFullReductionList: [],
      productLadderList: [],
      productPresaleExtraList: [],
      redPacketList: [],
      skuStockList: []
    }
  },
  components: { Footer },
  watch: {
    // 'thisRightTest.guigevalue' (v, oldv) {
    //   const price = Number(this.thisRightTest.guigevalue)
    //   const weigth = Number(v) * price
    //   this.allPrice = weigth.toFixed(2)
    // },
    'thisRightTest.weightvalue' (v, oldv) {
      this.allPrice = (v * this.thisRightTest.guigevalue) + (this.thisRightTest.yutivalue * v)
    },
    'thisRightTest.yutivalue' (v, oldv) {
      this.allPrice = ((this.thisRightTest.weightvalue * this.thisRightTest.guigevalue) + (v * this.thisRightTest.weightvalue))
    }
  },
  computed: {
    daikuan: {
      get () {
        return (this.allPrice - (this.thisRightTest.guigevalue * this.thisRightTest.weightvalue / 100) * this.thisRightTest.fukuanvalue)
      }
    }
  },
  methods: {
    isact (k, name) {
      switch (name) {
        case 'isactCarimg':
          this.isactCarimg = k
          this.thisimgurl = this.product.albumPics[k]
          break
        case 'isactive':
          this.isactive = k
          this.thisStock = this.skuStockList[k] // 选择的规格参数
          break
        default:
          break
      }
    },
    carDivBtnNextLast (sta) {
      if (sta === 'last') {
        this.isactCarimg === 0 ? this.isactCarimg = this.product.albumPics.length - 1 : this.isactCarimg--
        this.thisimgurl = this.product.albumPics[this.isactCarimg]
      } else {
        this.isactCarimg === this.product.albumPics.length - 1 ? this.isactCarimg = 0 : this.isactCarimg++
        this.thisimgurl = this.product.albumPics[this.isactCarimg]
      }
    },
    // 数量的增加减少限制
    handleChange (value) {
      // this.formData.mount = value
      // console.log(value)
      const reselldata = this.resellBoughtData
      const minBuy = reselldata.pmsProduct.minBuy // 最小订货量
      const minIncr = reselldata.pmsProduct.minIncr // 最小增量
      const zengGou = value - minBuy // 增购的数量(选择的数量-最小订货量)
      const okResell = (reselldata.omsOrderItem.productQuantity - reselldata.resaleNum) - value // 剩余数量
      if (value === reselldata.omsOrderItem.productQuantity - reselldata.resaleNum - minIncr) {
        this.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum - minBuy
      } else {
        if (okResell < minBuy) {
        // console.log(okResell)
        // ElMessage.error({ message: `${minBuy}` })
        // this.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum
          if (okResell !== 0) {
            this.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum
          } else {
            this.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum
          }
        } else {
          if (okResell === minBuy) {
            this.formData.mount = reselldata.omsOrderItem.productQuantity - reselldata.resaleNum - minBuy
          } else {
            if (parseInt(okResell / minBuy)) {
              // console.log(okResell)
              // console.log(okResell / minBuy)
              if (zengGou >= minIncr) {
                // console.log(Math.ceil(zengGou / minIncr))
                this.formData.mount = minBuy + Math.ceil(zengGou / minIncr) * minIncr
              } else {
                this.formData.mount = minIncr
              }
            } else {
              ElMessage.error({ message: `剩余不得低于最小订货量${minBuy}` })
              this.formData.mount = minBuy
            }
          }
        }
      }
    },
    // 删除所选项 ,或者重置已选择
    handleClose (tag, tagslist) {
      // console.log(typeof tag)
      // console.log(tag)
      if (typeof tag !== 'string') {
        this.timeTags.splice(0, tag.length)
      } else {
        this.timeTags.splice(tagslist.indexOf(tag), 1)
      }
    },
    toBuyPage () {
      // console.log(toRaw(this.thisStock))
      // console.log(JSON.stringify(toRaw(this.timeTags)))

      const data = {
        resaleProductId: this.pmsResaleProduct.id,
        resaleFlag: 0,
        SkuId: this.thisStock.id,
        SkuCode: this.thisStock.skuCode,
        number: this.thisBuyNum
      }

      // console.log(data)

      http.post('/mall-order/order/resaleGenerateConfirmOrder', data, true)
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            try {
              const loading = this.$loading({
                lock: true,
                text: '加载中'
              })
              setTimeout(() => {
                setStore('resellOrderMessage', JSON.stringify(res.data.data))
                // console.log(typeof data[0].resaleFlag)

                this.$router.push({
                  path: '/buyresell',
                  query: {
                    name: this.product.name,
                    产地: this.product.originPlace,
                    time: encodeURIComponent(JSON.stringify(toRaw(this.timeTags)))
                    // prodata: encodeURIComponent(JSON.stringify(res.data.data))
                  }
                })

                // window.open(this.$router.href)
                loading.close()
              }, 1000)
            } catch (error) {
              console.log(error)
            }
          } else {
            ElMessage.error({ message: res.data.msg })
          }
        }).catch(err => {
          console.log(err)
        })
    },
    changeNum (e) {
      console.log(e)
    },
    async likeVarietiy () { // 关注商品
      const data = {
        previewStatus: 4,
        productId: this.$route.query.id,
        productSn: null
      }
      const res = await followTheProduct(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    async confirmEvent () { // 关注供应商
      // console.log('confirm!')
      const data = {
        merchantId: this.companyInfo.memberId
      }
      if (this.product.focusOnMerchant === 1) {
        const res = await unpmsfollowthestore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '取关成功'
          })
          this.product.focusOnMerchant = 0
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      } else {
        const res = await followTheStore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '关注成功!'
          })
          this.product.focusOnMerchant = 1
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      }
    },
    cancelEvent () {
      // 取消
    }
  },
  created () {
    // console.log(this.$route.query.resaleSn)

    const loading = this.$loading({
      lock: true,
      text: '查询中...'
    })
    // const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
    const userId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null
    console.log(userId)
    const data = {
      id: this.$route.query.id,
      userId: userId ? userId.id : null
    }
    http.get('/mall-order/resale/detail', data)
      .then(res => {
        console.log(res)
        this.comList = res.data.data
        // ========
        this.brand = this.comList.brand
        this.companyInfo = this.comList.companyInfo
        this.couponList = this.comList.couponList
        this.pmsResaleProduct = this.comList.pmsResaleProduct
        this.pmsProductPreTakeWayList = this.comList.pmsProductPreTakeWayList
        this.pmsSiteEntityHashMap = this.comList.pmsSiteEntityHashMap
        this.product = this.comList.product
        this.productAttributeList = this.comList.productAttributeList
        this.productAttributeValueList = this.comList.productAttributeValueList
        this.productFullReductionList = this.comList.productFullReductionList
        this.productLadderList = this.comList.productLadderList
        this.productPresaleExtraList = this.comList.productPresaleExtraList
        this.redPacketList = this.comList.redPacketList
        this.skuStockList = this.comList.skuStockList

        // console.log(this.product.albumPics)
        for (const i of this.skuStockList) {
          i.spData = JSON.parse(i.spData)
        }
        this.validPeriodOffer = this.skuStockList[0].spData[0].value
        this.product.minBuy = Number(this.product.minBuy)
        this.thisBuyNum = this.product.minBuy // 默认最小购买量
        this.thisRightTest.weightvalue = this.product.minBuy
        // 赋值给原数据
        this.product.albumPics = this.comList.product.albumPics.split(',') // 轮播图
        this.thisimgurl = this.product.albumPics[0]
        this.thisStock = this.skuStockList[0]
        this.thisBeginDay = this.productPresaleExtraList[0].advancePikegoodsDaysBegin
        this.thisEndDay = this.productPresaleExtraList[0].advancePikegoodsDaysEnd

        this.commMess[0].cont = this.product.description
        this.commMess[1].cont = this.product.createTime
        // this.commMess[2].cont = this.product.description
        this.commMess[3].cont = this.product.deliveryPlace
        this.commMess[4].cont = this.product.deliveryPlace

        this.thisRightTest.guigevalue = this.pmsResaleProduct.resalePrice
        this.thisRightTest.fukuanvalue = this.product.prePayRate // 预估付款比例

        this.endTime = this.product.offerExpiryDate // 报价有效期 =====》 提货截至时间
        this.previewStatus = this.product.previewStatus // 是否是预售 0->不是；1->是

        // const begin = this.thisBeginDay // 提前开始时间
        // const finish = this.thisEndDay // 提前结束时间
        // console.log(this.previewStatus, this.endTime, this.thisBeginDay / 10, this.thisEndDay / 10)
        this.atime = calcuTime(this.previewStatus, this.endTime, this.thisBeginDay / 10, this.thisEndDay / 10)
        this.tihuoValue = this.atime.beginDate
        loading.close()
      }).catch(err => {
        console.log(err)
        // console.log(calcuTime(this.previewStatus, this.endTime, this.thisBeginDay, this.thisEndDay))
        // calcuTime(1, '2022-1-25', 2, 10)
        // console.log(calcuTime(1, '2022-1-25', 2, 10))
      })
  },
  setup (props, ctx) {
    window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部

    return {
      headernav,
      data: ref('')
    }
  }
}
</script>

<style lang="scss">
@import '../../style/viriables.scss';

#page{
  background: #F2EFED;
  width: 100%;
  // height: 100%;
  .nav{
    // height: 30px;
    width: 100%;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            cursor: pointer;
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .main{
    width: 1200px;
    margin: 0 auto;
    font-family: Microsoft YaHei;
    font-weight: 400;
    .header{
      margin-top: 10px;
      width: 1200px;
      // height: 400px;
      background: #FFFFFF;
      display: flex;
      .left_img{
        padding: 20px;
        border-right: 1px solid #f2f2f2;
        .detailimg{
          height: 360px;
          width: 360px;
        }
        .carousel_div{
          height: 58px;
          width: 100%;
          display: flex;
          align-items: center;
          button{
            width: 30px;
            height: 54px;
            background: #f2f2f2;
            // opacity: 0;
            transition: all 0.3s;
          }
          // button
          .car_img_ul{
            // flex: 1;
            width: 300px;
            display: flex;
            // overflow: auto;
            // justify-content: space-between;
            .car_img_li{
              flex: 1;
              .li_span_img{
                display: flex;
                // flex-shrink: 0;
                justify-content: center;
                align-items: center;
                img{
                  border: 2px solid transparent;
                  width: 90%;
                  max-width: 50px;
                  min-width: 33px;
                  min-height: 33px;
                  max-height: 50px;
                  height: 90%;
                  transition: all 0.3s;
                }
              }
              .isact_carimg{
                img{
                  border-color: #C6824E;
                }
              }
            }
          }
        }
        .carousel_div:hover{
          button{
            // opacity: 1;
            background: #EAEAEA;
          }
        }
        .el-carousel{
          .el-carousel__container{
            .el-carousel__item{
              // width: 60px;
              img{
                display: block;
                margin: 0 auto;
                width: 58px;
                height: 58px;
              }
            }
          }
        }
      }
      .center_car{
        flex: 1;
        display: flex;
        flex-flow: column;
        padding: 20px;
        .center_title{
          display: block;
          .yushouCont{
            float: left;
            width: 30px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
            border-radius: 4px;
            margin-right: 5px;
          }
          .titlefont{
            font-size: 18px;
            color: #333333;
          }
        }
        .el-form{
          padding: 0;
          :first-child.el-form-item{
            margin: 20px 0;
          }
          .el-form-item{
            padding: 0 20px;
            margin-bottom: 0;
            // height: 40px;
            .el-form-item__label{
              text-align-last: justify;
              text-align: left;
              color: #999999;
            }
            .el-form-item__content{
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .saleclass{
                font-size: 20px;
                font-weight: bold;
                color: #C6824E;
              }
              .stock_num{
                color: #333333;
              }
              .dinghuo{
                margin-right: 50px;
                i{
                  color: #666666;
                  margin-right: 30px;
                }
              }
              .guige{
                display: flex;
                flex-wrap: wrap;
                .btn{
                  display: block;
                  float: left;
                  text-align: center;
                  height: 30px;
                  width: 75px;
                  border: 1px solid #EAEAEA;
                  margin-right: 8px;
                  margin-bottom: 8px;
                  border-radius: 2px;
                  line-height: 30px;
                  background: transparent;
                  box-shadow: none;
                  color: #333333;
                  cursor: pointer;
                  white-space: nowrap;
                  overflow: hidden;
                }
                .isactive{
                  border-color: #C6824E;
                  color: #C6824E;
                  background: url('../../static/imges/spanGg.png')no-repeat 62px 16px;
                }
              }
              .showallsp{
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
          .itemdiv{
            height: 30px;
            background: #FFEEE0;
            padding: 0 20px;
            .itemdiv_span{
              font-size: 12px;
              line-height: 30px;
              color: #C6824E;
              strong{
                font-size: 14px;
              }
            }
          }
          .tihuo_time{
            .el-form-item__content{
              align-items: flex-start;
              .block{
                // flex: 1;
                background: #FFFFFF;
                border-radius: 4px;
                display: flex;
                .el-input--prefix{
                  width: 135px;
                  align-items: center;
                  .el-input__inner{
                    // height: 40px;
                    border: 0;
                  }
                  input::-webkit-input-placeholder{
                    color: #999999;
                  }
                  .el-input__prefix-inner{
                    .el-icon{
                      color: #C6824E;
                    }
                  }
                }
              }
              .spanlist{
                display: flex;
                flex-wrap: wrap;
                .el-tag{
                  margin: 6px 10px 5px 0;
                }
              }
              .showallsp{
                position: absolute;
                right: -15px;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }
          .yuti{
            padding:0 20px;
            font-size: 12px;
            margin-bottom: 15px;
            span{
              color: #999999;
              margin-right: 40px;
              i{
                color: #333333;
              }
              .el-icon{
                top: 2px;
                color: #999999;
              }
            }
          }
        }
        .btnspan{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .btn_div{
            float: right;
            width: 40%;
            position: relative;
            height: 52px;
            background: url('../../static/imges/42559e2c11e8b82ba91e451581be42b.png') no-repeat;
            .gobutton{
              position: absolute;
              top: 10px;
              font-size: 14px;
              height: 30px;
              background: transparent;
              border-radius: 4px;
            }
            .gz{
              left: 33px;
              color: #673000;
            }
            .gm{
              left: 110px;
              color: #F2EFED;
            }
          }
        }
      }
      .right_test{
        width: 259px;
        .righttitle{
          height: 58px;
          background: #C6824E;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 58px;
          text-align: center;
        }
        .right_form{
          margin: 40px 0;
          .el-form-item{
            margin-bottom: 0;
            .el-form-item__content{
              .el-input--mini{
                width: 165px;
              }
            }
          }
        }
        .jisuan{
          padding: 0 15px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          p{
            // line-height: 20px;
            margin-bottom: 17px;
            color: #999999;
            span{
              color: #333333;
            }
          }
        }
        .heji{
          text-align: right;
          padding: 0 15px;
          font-size: 14px;
          color: #333333;
          span{
            font-size: 20px;
          }
        }
      }
    }
    .detail{
      margin-top: 10px;
      display: flex;
      .supplier_message{
        width: 240px;
        height: 530px;
        margin-bottom: 50px;
        background: #FFFFFF;
        border: 1px solid #C6824E;
        padding: 0 20px;
        display: flex;
        flex-flow: column;
        h3{
          text-align: center;
          padding: 20px;
          font-size: 20px;
          font-weight: bold;
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          border-bottom: 2px solid #2B1001;
        }
        .supname{
          margin-top: 18px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        .supgood{
          margin-top: 18px;
          font-size: 12px;
          color: #C6824E;
        }
        .supxiangxi{
          flex: 1;
          margin: 20px 0;
          .xxdiv{
            display: flex;
            align-items: center;
            color: #666666;
            margin-top: 18px;
            font-size: 14px;
            line-height: 21px;
            .el-icon{
              margin-right: 5px;
              margin-top: 3px;
            }
            button{
              width: 80px;
              height: 26px;
              border: 1px solid #EAEAEA;
              border-radius: 4px;
              background: transparent;
              color: #666666;
            }
          }
          .suplogo{
            width: 100%;
            img{
              width: 200px;
              // height: 200px;
              border-radius: 50%;
            }
          }
        }
      }
      .supplier_commodity{
        background: #FFFFFF;
        flex: 1;
        margin-left: 10px;
        padding: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        h2{
          color: #333333;
          font-size: 20px;
          margin: 10px 0 20px;
        }
        .commodity_message{
          height: 90px;
          box-sizing: border-box;
          background: #f7f7f7;
          padding: 28px 20px;
          .el-breadcrumb{
            display: flex;
            .el-breadcrumb__item{
              flex: 1;
              display: flex;
              .el-breadcrumb__inner{
                flex: 1;
                .com_body{
                  span{
                    display: block;
                    margin-bottom: 12px;
                    color: #333333;
                  }
                  p{
                    color: #999999;
                  }
                }
              }
              .el-breadcrumb__separator{
                margin-right: 15px;
                line-height: 40px;
              }
            }
          }
        }
        .commodity_content{
          .cont_message{
            font-size: 14px;
            color: #666666;
            line-height: 21px;
            margin-bottom: 20px;
          }
          .cont_img{
            max-width: 867px;
            overflow:visible;
            overflow: auto;
          }
        }
        .coffee_mes{
          ul{
            display: flex;
            flex-flow: wrap;
            padding: 0 20px;
            .coffee_li{
              min-height: 50px;
              width: 50%;
              display: flex;
              h4{
                line-height: 30px;
                white-space: nowrap;
              }
              span{
                line-height: 30px;
                color: #666666;
                word-break: break-all;
              }
            }
            .coli1{
              width: 100%;
            }
          }
        }
      }
    }
  }
 .divfooter{
    background: #280E01;
  }
}
</style>
